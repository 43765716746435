.info-modal-container {
  position: absolute;
  opacity: 85%;
  right: 0;
  border-radius: 50px;
  margin-top: 75px;
  margin-right: 15px;
  font-family: 'Karrik', Arial, Helvetica, sans-serif;
  font-size: 30px;
  letter-spacing: 0px;
  max-height: 500px;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(1, 1, 1, 1);
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  overflow: hidden;
  width: 350px;
  z-index: 999;
}

.info-modal-container.mobile {
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: 69px;
  font-size: 30px;
  top: 15px;
  padding: 2px;
  width: 300px;
}

.info-modal-inner-container {
  padding: 20px 30px;
}

.info-modal-inner-container p {
  margin-top: 0;
  margin-bottom: 0;
}

.info-modal-link-container {
  cursor: ne-resize;
}

.info-modal-link {
  cursor: ne-resize;
  text-decoration: none;
  color: unset;
  font-family: 'Karrik-Italic', sans-serif;
}

.info-modal-link-icon {
  font-family: 'Travelcons', sans-serif;
  font-size: 21px;
  margin-left: 2px;
}

.shows-info {
  font-family: 'Karrik-Italic'
}
