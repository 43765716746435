.media-button {
  position: absolute;
  cursor: pointer;
  z-index: 999;
  margin-left: 12px;
  margin-bottom: 12px;
  bottom: 0;
  left: 0;
}

.audio-info-container {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 34px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Karrik-Italic', sans-serif;
  font-size: 20px;
  z-index: 999;
  position: absolute;
  bottom: 21px;
  left: 83px;
  transition: all 0.2s;
}

.audio-info-container.mobile {
  font-size: 15px;
  left: unset;
  right: 10px;
}
