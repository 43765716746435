footer {
  width: 100vw;
  height: 67px;
  display: flex;
  z-index: 900;
  opacity: 0.8;
  background: transparent;
  transition: background 0.2s;
  position: absolute;
  bottom: 0;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}
