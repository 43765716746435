.nett-logo-container {
  line-height: 61px;
  cursor: pointer;
  user-select: none;
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.25s;
}

.nett-logo-container.visible {
  opacity: 1;
}

.nett-logo-main-text {
  font-family: 'Karrik-Italic', sans-serif;
  font-size: 94px;
  letter-spacing: -7px;
}

.nett-logo-sub-text {
  font-family: 'Karrik', sans-serif;
  font-size: 18px;
  letter-spacing: -1px;
  margin-left: 3px;
}
