.navigation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
}

.navigation-item {
  cursor: pointer;
  margin-right: 10px;
}

.navigation {
  display: flex;
  justify-content: space-evenly;
}

.current-menu-text {
  margin-right: 15px;
  font-size: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.current-menu-text {
  position: relative;
  top: 2px;
}
