header {
  padding-top: 3px;
  height: 67px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 999;
  width: 100vw;
  background: transparent;
  transition: background 0.2s;
  opacity: 0.8;
}

header.visible {
  background: white;
}
