.contact-icon {
  font-family: 'Travelcons', sans-serif;
  font-size: 20px;
  position: relative;
}

.shows-icon {
  font-family: 'Travelcons', sans-serif;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.info-icon {
  font-family: 'Karrik', sans-serif;
  font-size: 30px;
}

.play-icon {
  margin-left: 5px;
  margin-top: 5px;
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-style: solid;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-width: 2px;
  transition: all 0.2s;
}
